import { ReactComponent as Favorite } from 'app-images/icons/searchmarket-favorite.svg'
import { ReactComponent as Fire } from 'app-images/icons/searchmarket-fire.svg'
import { ReactComponent as Gem } from 'app-images/icons/searchmarket-gem.svg'
import { ReactComponent as Money } from 'app-images/icons/searchmarket-moneybag.svg'
import { ReactComponent as Rocket } from 'app-images/icons/searchmarket-rocket.svg'
import { ReactComponent as Skull } from 'app-images/icons/searchmarket-skull.svg'
import { ReactComponent as Voltage } from 'app-images/icons/searchmarket-voltage.svg'
import { ReactElement, ReactNode } from 'react'

import { AmplitudeEvent } from '../../../constants/amplitudeEvents'
import { TOKEN_BALANCE_LIMIT_HINT } from '../../../constants/resourceStrings'
import { Filter } from '../../../model'

export interface DropdownSearchTabProps {
  icon?: ReactElement
  text?: ReactNode
  details?: ReactNode
  title?: string
  headerTooltip?: string
  headerOrder?: number
  headerAmplitudeEvent?: AmplitudeEvent
  isProtected?: boolean
}

export const DropdownSearchTabs: Record<Filter, DropdownSearchTabProps> = {
  up: {
    title: 'Top Volume',
    headerTooltip: 'Top Volume',
    headerOrder: 1,
    icon: <Gem />,
    text: (
      <>Most popular tokens in the past 24&nbsp;hours based on highest total transaction volume</>
    ),
    headerAmplitudeEvent: AmplitudeEvent.CLICKED_TOP_VOLUME_IN_HEADER,
  },
  trending: {
    title: 'Trending',
    headerTooltip: 'Trending',
    headerOrder: 2,
    text: (
      <>
        Trending tokens in the past 24&nbsp;hours based on activity &amp;&nbsp;volume from Whales
        (Traders with &gt;$500k of a trading volume over the last 30&nbsp;day)
      </>
    ),
    icon: <Fire />,
    headerAmplitudeEvent: AmplitudeEvent.CLICKED_TRENDING_IN_HEADER,
  },
  gainers: {
    title: 'Gainers',
    headerTooltip: 'Gainers',
    headerOrder: 3,
    text: (
      <>
        Best performing tokens in the past 24&nbsp;hours by %&nbsp;gain with transaction volume
        &gt;$50,000
      </>
    ),
    icon: <Rocket />,
    headerAmplitudeEvent: AmplitudeEvent.CLICKED_GAINERS_IN_HEADER,
  },
  losers: {
    title: 'Losers',
    headerTooltip: 'Losers',
    headerOrder: 4,
    text: (
      <>
        Worst performing tokens in the past 24&nbsp;hours by %&nbsp;loss with transaction volume
        &gt;$50,000
      </>
    ),
    icon: <Skull />,
    headerAmplitudeEvent: AmplitudeEvent.CLICKED_LOSERS_IN_HEADER,
  },
  recent: {
    title: 'Recently Listed',
    headerTooltip: 'Recently Listed',
    headerOrder: 5,
    text: (
      <>
        Recently listed tokens in the past 24 hours with at least a single transaction and
        &gt;$50,000 in liquidity.
        <br />
        Tokens below are also under Degen Mode as they are not on verified token lists, remember to
        DYOR and verify contract addresses.{' '}
        <a
          href="https://docs.dex.guru/general/faq#what-is-the-full-degen-mode-inside-the-market-selector"
          target="_blank"
          rel="noreferrer noopener">
          Learn more
        </a>
      </>
    ),
    icon: <Voltage />,
    headerAmplitudeEvent: AmplitudeEvent.CLICKED_RECENTLY_LISTED_IN_HEADER,
  },
  favorite: {
    title: 'Favorites',
    headerTooltip: 'Favorites',
    text: 'All Tokens you currently have favorited.',
    icon: <Favorite />,
    headerOrder: 6,
    headerAmplitudeEvent: AmplitudeEvent.FAVORITED_LIST_OPENED_FROM_HEADER,
    isProtected: true,
  },
  'wallet-tab': {
    title: 'Wallet',
    headerTooltip: 'Wallet',
    headerOrder: 7,
    text: (
      <>
        Tokens &amp; LP Tokens inside your wallet across supported chains.{' '}
        {TOKEN_BALANCE_LIMIT_HINT}
      </>
    ),
    icon: <Money />,
    isProtected: true,
    headerAmplitudeEvent: AmplitudeEvent.CLICKED_WALLET_IN_HEADER,
  },
}
