import { ReactComponent as IconClose } from 'app-images/icons/close.svg'
import { ReactComponent as FavoriteSvg } from 'app-images/icons/emoji/favorite.svg'
import { ReactComponent as SearchIcon } from 'app-images/icons/search.svg'
import classNames from 'classnames'
import React, { Dispatch, FC, SetStateAction, Suspense, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { addFavorite, refreshTokens, removeFavorite } from '../../actions/tokenActions'
import { setHeaderFavoriteAnimationActive, setOmniboxSearchOpen } from '../../actions/uiActions'
import { useAuth } from '../../containers/AuthWrapper'
import { financialFormatLowValueHighPrecision } from '../../helpers/priceAndAmountHelpers'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { Filter, TokenV3, TokenWithApprovalContext, TrendingTokenV3 } from '../../model'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import { playFavoriteSound, playUnfavoriteSound } from '../../services/soundService'
import { getMissingTokenIds } from '../../services/tokenService'
import { getCurrencyName, getMarketDisplayName, pickValueBasedOnCurrency } from '../../utils'
import Delta from '../Delta'
import TokenAsset from '../TokenAsset'

const DropdownSearch = React.lazy(() => import('./Dropdown/DropdownSearch'))

interface OmniboxProps {
  isActiveSearch: boolean
  filter: Filter
  setFilter: Dispatch<SetStateAction<Filter>>
}

const Omnibox: FC<OmniboxProps> = (props: OmniboxProps) => {
  const searchQueryRef = useRef<HTMLInputElement>(null)
  const heartInitialRef = useRef<SVGSVGElement>(null)
  const heartIconRef = useRef<HTMLElement>(null)
  const { isAuth, openProviderMenu } = useAuth()
  const { isProviderMenuOpen } = useSelector((state: State) => state.ui)

  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const selectedCurrency = useSelector((state: State) => state.currency)
  const isMobile = useSelector((state: State) => state.isMobile)
  const [searchSymbol, setSearchSymbol] = useState('')
  const [selectedToken, setSelectedToken] = useState(currentToken)
  const [price, setPrice] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [debouncedText] = useDebounce(searchSymbol, 1000)
  const { favorites, tokens: tokensData } = useSelector((store: State) => store.tokens)
  const isFavoriteToken = favorites.find((tokenId) => tokenId === currentToken?.id)
  const reduxDispatch = useDispatch()

  const [isProviderMenuOpenOnce, setIsProviderMenuOpenOnce] = useState(false)

  const [filteredTokens, setFilteredTokens] = useState<TokenV3[]>([])

  const isActiveSearch = useRef<boolean>(props.isActiveSearch)

  const { isHeaderFavoriteAnimationActive } = useSelector((store: State) => store.ui)

  useEffect(() => {
    if (
      !isAuth &&
      props.filter === Filter.favorite &&
      isProviderMenuOpen &&
      !isProviderMenuOpenOnce
    ) {
      setIsProviderMenuOpenOnce(true)
    }
  }, [isAuth, props.filter, isProviderMenuOpen, isProviderMenuOpenOnce])

  // should load tokens entries, when open favorites tab
  useEffect(() => {
    if (!props.isActiveSearch || !favorites.length) {
      return
    }

    const tokenIds = getMissingTokenIds(favorites, tokensData)
    if (!tokenIds.length) {
      return
    }

    reduxDispatch(refreshTokens(tokenIds))
  }, [props.isActiveSearch, favorites, reduxDispatch, tokensData.length, tokensData])

  useComponentDidMount(() => {
    window.addEventListener('keydown', handleKeys)

    return (): void => {
      window.removeEventListener('keydown', handleKeys)
    }
  })

  const handleKeys = (event: KeyboardEvent): void => {
    if (event.code === 'Backquote' || event.code === 'IntlBackslash') {
      // pressed: ~ §
      if (!document.querySelector('.omnibox-search__query:focus')) {
        event.preventDefault()
        setSearchSymbol('')
        searchFocus()
      }
    }

    if (event.code === 'Escape') {
      onEsc(event)
    }
  }

  useEffect(() => {
    isActiveSearch.current = props.isActiveSearch
  }, [props.isActiveSearch])

  useEffect(() => {
    if (searchQueryRef.current) {
      searchQueryRef.current.placeholder = !isMobile ? 'Search Market' : ''
    }

    if (currentToken?.id) {
      setSelectedToken(currentToken)
    }
  }, [currentToken, isMobile])

  useEffect(() => {
    if (currentToken?.priceETH || currentToken?.priceUSD) {
      const currentPrice = pickValueBasedOnCurrency(
        selectedCurrency,
        currentToken.priceUSD,
        currentToken.priceETH
      )

      setPrice(currentPrice)
    }
  }, [currentToken?.priceETH, currentToken?.priceUSD, selectedCurrency])

  useEffect(() => {
    if (!isLoading && searchQueryRef?.current) {
      searchQueryRef.current.focus()
    }
  }, [isLoading, searchQueryRef])

  if (!currentToken) {
    return null
  }

  const searchShow = (e: React.MouseEvent<HTMLElement>): void => {
    const element = e.target
    // Here we check if the element isn't a "favorite heart" icon.
    // Because click on it should toggle token from favorites list
    if (
      element instanceof Element &&
      !element?.classList.contains('omnibox-token__favorite') &&
      !element?.closest('.omnibox-token__favorite')
    ) {
      searchFocus()
    }
  }

  const onEsc = (e: KeyboardEvent): void => {
    if (isActiveSearch.current) {
      e.stopImmediatePropagation()
      setSearchSymbol('')
      searchHide()
      searchQueryRef.current?.blur()
    }
  }

  const searchHide = (): void => {
    if (
      !isAuth &&
      props.filter === Filter.favorite &&
      isProviderMenuOpenOnce &&
      isProviderMenuOpen
    ) {
      setIsProviderMenuOpenOnce(false)
      return
    }

    reduxDispatch(setOmniboxSearchOpen(false))
    props.setFilter(Filter.up)
    setSearchSymbol('')
    if (searchQueryRef?.current) {
      searchQueryRef.current.placeholder = !isMobile ? 'Search Market' : ''
      searchQueryRef.current.blur()
    }
  }

  const searchFocus = (): void => {
    reduxDispatch(setOmniboxSearchOpen(true))
    if (searchQueryRef?.current) {
      searchQueryRef.current.placeholder = 'Search Market, use ticker or token address'
      searchQueryRef.current.focus()
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchSymbol(e.target.value)
  }

  const onClickFavoriteIcon = (): void => {
    if (!isAuth) {
      // better without callback since user doesn't know if it was already a favorite
      openProviderMenu()
    } else {
      doFavoriteToken()
    }
  }

  if (!selectedToken) {
    return null
  }

  const doFavoriteToken = async (): Promise<void> => {
    const wasTokenFavorite = favorites?.find((tid: string) => tid === selectedToken.id) || false
    const tokenName = getMarketDisplayName(currentToken)

    if (wasTokenFavorite) {
      amplitudeService.removedFavorite(selectedToken.id, tokenName, selectedToken.network)
      reduxDispatch(removeFavorite(selectedToken.id))
      playUnfavoriteSound(!!isMobile)

      reduxDispatch(setHeaderFavoriteAnimationActive(false))
      if (heartIconRef.current) {
        heartIconRef.current.style.cssText = ''
      }
    }

    if (!wasTokenFavorite) {
      amplitudeService.addedFavorite(selectedToken.id, tokenName, selectedToken.network, false)
      reduxDispatch(addFavorite(selectedToken.id))
      playFavoriteSound(!!isMobile)

      const target = document.querySelector('.header .favorites-target')
      reduxDispatch(setHeaderFavoriteAnimationActive(true))

      if (target && heartInitialRef.current && heartIconRef.current) {
        const coordStart = heartInitialRef.current.getBoundingClientRect()
        const coordEnd = target.getBoundingClientRect()

        heartIconRef.current.style.cssText = `
            --start-x: ${coordStart.left}px;
            --start-y: ${coordStart.top}px;
            --end-x: ${coordEnd.left}px;
            --end-y: ${coordEnd.top}px;
          `
      }
    }
  }

  const priceChange24h = pickValueBasedOnCurrency(
    selectedCurrency,
    selectedToken.priceUSDChange24h,
    selectedToken.priceETHChange24h
  )

  const currencyDisplayName = getCurrencyName(selectedToken.network, selectedCurrency)

  const renderKeysForMultipleRows = (): React.ReactElement => (
    <div className="omnibox-search__hints">
      <span className="omnibox-search__hint">
        <kbd>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="10" height="12">
            <path stroke="#fff" d="M5 12V1m0 0L1 5m4-4l4 4" />
          </svg>
        </kbd>
      </span>
      <span className="omnibox-search__hint">
        <kbd>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="10" height="12">
            <path stroke="#fff" d="M5 0v11m0 0l4-4m-4 4L1 7" />
          </svg>
        </kbd>
      </span>
      <span className="omnibox-search__hint omnibox-search__hint--close" onClick={searchHide}>
        <kbd>
          <small>ESC</small>
        </kbd>
      </span>
      <span className="omnibox-search__hint omnibox-search__hint--open" onClick={searchShow}>
        <kbd>~</kbd>
      </span>
    </div>
  )

  const renderKeysForSingleRow = (): React.ReactElement => (
    <div className="omnibox-search__hints">
      <span className="omnibox-search__push">Push</span>
      <span className="omnibox-search__hint omnibox-search__hint--close">
        <kbd>
          <small>ENTER</small>
        </kbd>
      </span>
      <span className="omnibox-search__hint omnibox-search__hint--close" onClick={searchHide}>
        <kbd>
          <small>ESC</small>
        </kbd>
      </span>
      <span className="omnibox-search__hint omnibox-search__hint--open" onClick={searchShow}>
        <kbd>~</kbd>
      </span>
    </div>
  )

  return (
    <div className={classNames('omnibox', { 'omnibox--open': props.isActiveSearch })}>
      {!props.isActiveSearch && (
        <>
          <div className="omnibox__intro-ref" />
          <div className="omnibox__token omnibox-token" onClick={searchShow}>
            {!isMobile && (
              <span
                className={classNames('omnibox-token__favorite', {
                  active: isFavoriteToken,
                })}
                onClick={onClickFavoriteIcon}>
                <span className="icon" ref={heartIconRef}>
                  {isHeaderFavoriteAnimationActive && isFavoriteToken && (
                    <FavoriteSvg className="animate" />
                  )}
                  <FavoriteSvg ref={heartInitialRef} />
                </span>
              </span>
            )}
            <div className="omnibox-token__asset">
              <TokenAsset token={selectedToken} size="sm" />
            </div>
            <div className="omnibox-token__data">
              <div className="omnibox-token__price">
                {selectedCurrency === 'USD' && <span className="sign">$</span>}
                <span className="value" title={financialFormatLowValueHighPrecision(price)}>
                  <span className="caption">{financialFormatLowValueHighPrecision(price)}</span>
                </span>
                {selectedCurrency !== 'USD' && (
                  <span className="sign">&nbsp;{currencyDisplayName}</span>
                )}
              </div>
              <div className="omnibox-token__delta">
                <Delta value={priceChange24h * 100} size={isMobile ? 'sm' : 'md'} />
              </div>
            </div>
          </div>
          <div className="omnibox__search" onClick={searchFocus}>
            <div className="omnibox-search">
              <div className="omnibox-search__icon">
                <div className="icon">
                  <SearchIcon />
                </div>
              </div>
              {!isMobile && (
                <span className="omnibox-search__hint" onClick={searchShow}>
                  <kbd>~</kbd>
                </span>
              )}
            </div>
          </div>
        </>
      )}

      {props.isActiveSearch && (
        <>
          <div className="omnibox__overlay" onClick={searchHide} />
          <div className="omnibox__search">
            <div className="omnibox-search">
              <div className="omnibox-search__icon">
                <div className="icon">
                  <SearchIcon />
                </div>
              </div>
              <input
                className="omnibox-search__query"
                placeholder="Search Market"
                onFocus={searchFocus}
                onChange={onChange}
                value={searchSymbol}
                ref={searchQueryRef}
                disabled={isLoading}
              />

              {!isMobile && filteredTokens.length > 1 && renderKeysForMultipleRows()}
              {!isMobile && filteredTokens.length === 1 && renderKeysForSingleRow()}

              {isMobile && (
                <div className="omnibox-search__close" onClick={searchHide}>
                  <div className="icon">
                    <IconClose className="" />
                  </div>
                </div>
              )}
            </div>

            {props.isActiveSearch && (
              <div className="omnibox__dropdown">
                <Suspense fallback={<div className="dropdown-search dropdown-search--active" />}>
                  <DropdownSearch
                    onFilteredTokensChange={setFilteredTokens}
                    searchHide={(selected?: TokenWithApprovalContext | TrendingTokenV3): void => {
                      if (selected) {
                        setSelectedToken(selected as TokenWithApprovalContext)
                      }
                      searchHide()
                    }}
                    debouncedText={debouncedText}
                    filter={props.filter}
                    setFilter={props.setFilter}
                    setSearchSymbol={setSearchSymbol}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </Suspense>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default Omnibox
